<template>
  <div id="vendor">
    <b-row class="mb-5">
      <b-col>
        <h1>{{ $t('Sellers') }}</h1>
        <h5 class="text-primary">
          {{ $t('sellersShop') }}
        </h5>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="routeToView"
        >
          <feather-icon
            icon="FolderPlusIcon"
            class="mr-50"
          />
          {{ $t('Add') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="vend in allVendors"
        :key="vend.node.id"
        class="mt-5 "
        md="6"
        lg="4"
        xl="3"
        sm="12"
      >
        <b-card
          class="carVendor"
          no-body
        >
          <b-img
            :src="buildImageUrl(vend)"
            class="card-img-top cursor-pointer"
            img-alt="Card image"
            fluid-grow
            @click="routeToEditView(vend.node.id)"
            @error="errorImg"
          />
          <b-card-header>
            <div id="acortarPadre">
              <b-card-title
                id="acortar"
                class="mb-25"
              >
                {{ vend.node.vendorName }}
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body>
            <div class="actions">
              <div class="action">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      color="white"
                      icon="MoreVerticalIcon"
                      size="20"
                    />
                  </template>
                  <b-dropdown-item @click="routeToEditView(vend.node.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      size="18"
                    />
                    {{ $t('dataGeneric.edit') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteVendor(vend.node.id)">
                    <feather-icon
                      icon="DeleteIcon"
                      size="18"
                    />
                    {{ $t('dataGeneric.delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BImg,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover_1by1.png')

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BImg,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      orders: null,
      allVendors: [],
      userData: getUserData(),

    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.overlay = true
      axios
        .post('', {
          query: `   
                    {
                    allVendors(client: "${this.userData.profile.client.id}") {
                        edges {
                            node {
                            id
                            vendorName
                            name
                            lastName
                            vendorImage
                            imageVendorUrl
                            }
                        }
                    }
                }
                `,
        })
        .then(response => {
          messageError(response, this)

          this.allVendors = response.data.data.allVendors.edges
          this.overlay = false
        })
        .catch(err => (
          console.log(err)
        ))
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover_1by1.png'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
    errorImg(e) {
      e.target.src = fileError
    },
    deleteVendor(id) {
      this.overlay = true

      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = ''

          query = `
                        mutation{
                        deleteVendors(id:"${id}") {
                            found
                            deletedId
                        }
                        }`
          axios
            .post('', {
              query,
            })
            .then(res => {
              messageError(res, this)

              this.allVendors = this.allVendors.filter(item => item.node.id !== id)

              showToast(this.$t('success'), 1, this)

              this.overlay = false
            })
            .catch(() => {
              this.overlay = false
              showToast(this.$t('error'), 2, this)
            })
        } else {
          this.overlay = false
        }
      })
    },
    routeToView() {
      this.$router.push({ name: 'create-vendor' })
    },
    buildImageUrl(folder) {
      return folder.node.imageVendorUrl == null || folder.node.imageVendorUrl.length === 0
        ? noCover
        : folder.node.imageVendorUrl
    },
    routeToEditView(id) {
      this.$router.push({ name: 'edit-vendor', params: { id } })
    },
  },

}
</script>

<style scoped lang="scss">

#vendor .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#vendor .card {
  width: 80%;
}

#vendor .card-header {
  height: 1rem;
}

#vendor .cardContent img {
  height: 11vw;
  object-fit: cover;

}

#vendor .card-body h4 {
  font-size: 1rem !important;
}

#vendor .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 100; */
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  justify-content: flex-end;

}

#vendor .dropContainer {
  border: 3px dashed;
}

#vendor .list-group-item {
  transition: all 1s;
}

#vendor #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-size: medium;
}

#vendor #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#vendor .carVendor img {
  height: 11vw;
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  #vendor .carVendor img {
    height: 35vw;
    object-fit: cover;
  }
}

@media screen and (min-width: 992px) {

  //  Estilos para pantallas grandes (más de 992px de ancho)
  #vendor .carVendor img {
    height: 20vw;
    object-fit: cover;

  }
}

@media screen and (min-width: 1250px) {

  //  Estilos para pantallas grandes (más de 992px de ancho)
  #vendor .carVendor img {
    height: 12vw;
    object-fit: cover;

  }
}
</style>
